import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/ua/chapter_3/sub_1/slide1';
import Slide2 from 'src/slides/desktop/ua/chapter_3/sub_1/slide2';
import Slide3 from 'src/slides/desktop/ua/chapter_3/sub_1/slide3';
import SEO from 'src/components/_shared/seo';

import WrapperMobile from 'src/slides/mobile/ua/chapter_3/sub_1/wrapperMobile';
import { isMobile } from 'react-device-detect';

const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Відновлення незалежності Польщі в 1918 р I Варшавська битва" lang="ua" description="Історія відродження польської держави після 123 років неволі." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Відновлення незалежності Польщі в 1918 р I Варшавська битва" lang="ua" description="Історія відродження польської держави після 123 років неволі." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
